import React from "react"
import * as styles from './contact.module.css'


class ContactDetails extends React.Component {
  render() {
    return (
      <section className={styles.contact} id="location">
        <h3 class="titleWhite">Contact</h3>
        <h4 className={styles.contactUs}>Email us<br /><a href="mailto: info@move-dungarvan.ie">info@move-dungarvan.ie</a></h4>
        <h4 className={styles.contactUs}>Call us on<br /><a href="tel:+353852606736">+353852606736</a></h4>
      </section>
    )
  }
}

export default ContactDetails;