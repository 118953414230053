import React, { Component } from "react"
import Footer from "../components/footer/footer";
import Nav from "../components/nav/nav"
import Seo from "../components/seo/seo"
import Subscribe from "../components/subscribe/subscribe"
import ContactDetails from "../components/contact/contact"

class Contact extends Component {
  
  render() {
    return (
      <Seo title={'Contact'}>
        <ContactDetails />
        <Subscribe />
        <Footer />
        <Nav showLogo={true} isDarkBackground={true} />
      </Seo>
    )
  }
}

export default Contact;
